function getWindow() {
    return window;
}

window.getData = function() {

    var data = {};

    if (!getWindow()._fields) {
        return data;
    }

    for (var i = 0; i < getWindow()._fields.length; i++) {
        var field = getWindow()._fields[i];

        if (!field) {
            continue;
        }

        if (field.variable == 'phone_number') {
            data.phone = field.value.replaceAll(' ', '');
        }

        if (field.variable == 'address_line1') {
            data.address_line1 = field.value;
        }

        if (field.variable == 'address_line2') {
            data.address_line2 = field.value;
        }

        if (field.variable == 'address_city') {
            data.address_city = field.value;
        }

        if (field.variable == 'address_state') {
            data.address_state = field.value;
        }

        if (field.variable == 'address_postal_code') {
            data.address_postal_code = field.value;
        }

        if (field.variable == 'user_first_name') {
            data.firstname = field.value;
        }

        if (field.variable == 'user_last_name') {
            data.lastname = field.value;
        }

        if (field.variable == 'email') {
            data.email = field.value;
        }

    }

    data.fullname = data.firstname + ' ' + data.lastname;

    return data;

};

function bmifn(weight, height) {

    let kg = parseInt(weight) * 0.453;
    let m = parseInt(height) * 0.0254;

    let newwt = weight * 0.8;

    let bmi = kg / (m ** 2);

    let c = false;

    switch (true) {
        case bmi <= 18.5:
            c = false;
            break;

        case bmi <= 23.0:
            c = false;
            break;

        case bmi >= 23.01:

            c = true;
            break;

        default:
            c = true;

    }

    return c;
}

function converter(feet, inches) {
    var totalInches = parseInt(feet) * 12;

    return totalInches + parseInt(inches);
}

window.addEventListener('heyflow-change', (event) => {

    // if (event.detail.screenName == 'patient-weight') {

    //     var h = converter(event.detail.fieldsSimple.patient_height_feet, event.detail.fieldsSimple.patient_height_inches);
    //     var w = parseInt(event.detail.fieldsSimple.patient_weight);
    //     if (bmifn(w, h) == true) {

    //         window._fields = event.detail.fields;
    //         document.querySelector(`[id=input-a102fad5]`).value = 1;

    //     } else {
    //         window._fields = event.detail.fields;
    //         document.querySelector(`[id=input-a102fad5]`).value = 0;
    //     }

    // }

    window._fields = event.detail.fields;

});

window.addEventListener('heyflow-screen-view', (event) => {
    
    if([
        'questions-evaluation',
        'question-severity-1',
        'question-severity-2',
        'question-severity-3',
        'question-severity-4',
        'question-severity-5',
        'question-severity-6',
        'evaluate',
        'confirm-treatment'
        ].includes(event.detail.screenName) && getWindow()._fields
    ) {
    //     var qualified = false;
    //     var meets_min_requirement_move_to_severity = false;
    //     var show_all_medications = false;
        
    //     for(var i = 0; i < getWindow()._fields.length; i++) {
            
    //         var field = getWindow()._fields[i];
    //         var key = field.variable;
    //         var value = field.value;
            
    //         if([
    //             'question_1',
    //             'question_2',
    //             'question_3',
    //             'question_4',
    //             'question_5'
    //         ].includes(key) && meets_min_requirement_move_to_severity == false) {
                
    //             if(value == 'Yes') {
    //                 meets_min_requirement_move_to_severity = true;
    //             }
                
    //         }
            
    //         if([
    //             'question_6',
    //             'question_7',
    //             'question_8',
    //             'question_9',
    //             'question_10'
    //         ].includes(key) && meets_min_requirement_move_to_severity == false) {
                
    //             if(value == 'Yes') {
    //                 qualified = false;
    //             }
                
    //         }
            
    //         if([
    //             'question_11'
    //         ].includes(key) && meets_min_requirement_move_to_severity == true) {
                
    //             if(value == 'Yes') {
    //                 show_all_medications = true;
    //             }
                
    //         }
            
    //     }
        
        //if(meets_min_requirement_move_to_severity == true && event.detail.screenName == 'evaluate') {
            // document.getElementById('button-939e4e9f').click();
        // } else if(meets_min_requirement_move_to_severity == false && event.detail.screenName == 'evaluate') {
        //     document.getElementById('button-4b58ca02').click();
        // }
        
        // if(event.detail.screenName == 'confirm-treatment') {
        //     if(show_all_medications == false) {
        //         var eles = document.getElementById('mco-73af5862').closest('.multiple-choice-inner').querySelectorAll('.multiple-choice-option-container');
                
                
        //     }
        // }
        
    }
    
    if(event.detail.screenName == 'set-treatment-pricing') {
        
        if(getWindow()._fields) {
            
            for(var i = 0; i < getWindow()._fields.length; i++) {
            
                var field = getWindow()._fields[i];
                var key = field.variable;
                var value = field.value;
                
                if(key == 'treatment_option') {
                    
                    if(value == 'Sildenafil') {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                        window.dataLayer.push({
                          event: "begin_checkout",
                          ecommerce: {
                            currency: "USD",
                            value: 49.99,
                            items: [
                            {
                              item_name: "Sildenafil",
                              index: 0,
                              item_brand: "HLTHi",
                              item_category: "Erectile Dysfunction Treatment",
                              item_list_id: "related_products",
                              item_list_name: "Advance",
                              price: 49.99,
                              quantity: 1
                            }
                            ]
                          }
                        });
                        document.getElementById('id-7f7a1c90').click();
                    }
                    
                    if(value == 'Tadalafil') {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                        window.dataLayer.push({
                          event: "begin_checkout",
                          ecommerce: {
                            currency: "USD",
                            value: 49.99,
                            items: [
                            {
                              item_name: "Tadalafil",
                              index: 0,
                              item_brand: "HLTHi",
                              item_category: "Erectile Dysfunction Treatment",
                              item_list_id: "related_products",
                              item_list_name: "Advance",
                              price: 49.99,
                              quantity: 1
                            }
                            ]
                          }
                        });
                        document.getElementById('id-366c1563').click();
                    }
                    
                }
            
            }
            
        }
        
        
    }
    
    if (event.detail.screenName == 'choose-pharmcy') {
        var problems = document.getElementsByClassName('problem-row');

        var cvalues = [];

        for (var c = 0; c < problems.length; c++) {



            var problemName = problems[c].querySelector('.problem_name_input');
            var problemDate = problems[c].querySelector('.problem_date_input');

            if (!problemName) {
                continue;
            }

            if (!problemDate) {
                continue;
            }

            cvalues.push(problemName.value + " " + problemDate.value);

        }

        document.getElementById('id-1ddde704').value = cvalues.join(':||:');
    }
    
    if (event.detail.screenName == 'choose-pharmcy') {
        
         for(var i = 0; i < getWindow()._fields.length; i++) {
            
                var field = getWindow()._fields[i];
                var key = field.variable;
                var value = field.value;
                
                if(key == 'treatment_option') {
                    
                    if(value == 'Sildenafil') {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                        window.dataLayer.push({
                          event: "add_shipping_info",
                          ecommerce: {
                            currency: "USD",
                            value: 49.99,
                            shipping_tier: "Ground",
                            items: [
                            {
                              item_name: "Sildenafil",
                              index: 0,
                              item_brand: "HLTHi",
                              item_category: "Erectile Dysfunction Treatment",
                              item_list_id: "related_products",
                              item_list_name: "Advance",
                              price: 49.99,
                              quantity: 1
                            }
                            ]
                          }
                        });
                    }
                    
                    if(value == 'Tadalafil') {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                        window.dataLayer.push({
                          event: "add_shipping_info",
                          ecommerce: {
                            currency: "USD",
                            value: 49.99,
                            shipping_tier: "Ground",
                            items: [
                            {
                              item_name: "Tadalafil",
                              index: 0,
                              item_brand: "HLTHi",
                              item_category: "Erectile Dysfunction Treatment",
                              item_list_id: "related_products",
                              item_list_name: "Advance",
                              price: 49.99,
                              quantity: 1
                            }
                            ]
                          }
                        });
                    }
                    
                }
            
            }
            
        var allergies = document.getElementsByClassName('allergy-row');

        var cvalues = [];

        for (var c = 0; c < allergies.length; c++) {



            var allergyName = allergies[c].querySelector('.allergy_name_input');

            if (!allergyName) {
                continue;
            }

            cvalues.push(allergyName.value);

        }

        document.getElementById('id-a90fee73').value = cvalues.join(':||:');
    }
    
    if (event.detail.screenName == 'choose-pharmcy') {
        var meds = document.getElementsByClassName('medication-row');

        var cvalues = [];

        for (var c = 0; c < meds.length; c++) {



            var medName = meds[c].querySelector('.medication_name_input');
            var medInstruct = meds[c].querySelector('.medication_instruction_input');

            if (!medName) {
                continue;
            }

            if (!medInstruct) {
                continue;
            }

            cvalues.push(medName.value + " " + medInstruct.value);

        }

        document.getElementById('textarea-3cb4e8ec').value = cvalues.join(':||:');
    }
    
    if(event.detail.screenName == 'shipping-information') {
        for(var i = 0; i < getWindow()._fields.length; i++) {
            
                var field = getWindow()._fields[i];
                var key = field.variable;
                var value = field.value;
                
                if(key == 'treatment_option') {
                    
                    if(value == 'Sildenafil') {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                        window.dataLayer.push({
                          event: "add_payment_info",
                          ecommerce: {
                            currency: "USD",
                            value: 49.99,
                            payment_type: "Credit Card",
                            items: [
                            {
                              item_name: "Sildenafil",
                              index: 0,
                              item_brand: "HLTHi",
                              item_category: "Asthma Treatment",
                              item_list_id: "related_products",
                              item_list_name: "Advance",
                              price: 49.99,
                              quantity: 1
                            }
                            ]
                          }
                        });
                    }
                    
                    if(value == 'Tadalafil') {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                        window.dataLayer.push({
                          event: "add_payment_info",
                          ecommerce: {
                            currency: "USD",
                            value: 49.99,
                            payment_type: "Credit Card",
                            items: [
                            {
                              item_name: "Tadalafil",
                              index: 0,
                              item_brand: "HLTHi",
                              item_category: "Erectile Dysfunction Treatment",
                              item_list_id: "related_products",
                              item_list_name: "Advance",
                              price: 49.99,
                              quantity: 1
                            }
                            ]
                          }
                        });
                    }
                    
                }
            
            }
        
    }

    if (event.detail.screenName == 'checkout') {


        initialize(window.getData());

        return;
    }

});

function getCardElement() {
    return window.cardElement;
}

window.addEventListener('heyflow-submit', (event) => {

    
});

try {
    function Slider() {
        const carouselSlides = document.querySelectorAll('.slide');
        const btnPrev = document.querySelector('.prev');
        const btnNext = document.querySelector('.next');
        const dotsSlide = document.querySelector('.dots-container');
        let currentSlide = 0;

        const activeDot = function(slide) {
            document.querySelectorAll('.dot').forEach(dot => dot.classList.remove('active'));
            document.querySelector(`.dot[data-slide="${slide}"]`).classList.add('active');
        };
        activeDot(currentSlide);

        const changeSlide = function(slides) {
            carouselSlides.forEach((slide, index) => (slide.style.transform = `translateX(${100 * (index - slides)}%)`));
        };
        changeSlide(currentSlide);

        btnNext.addEventListener('click',
            function() {
                currentSlide++;
                if (carouselSlides.length - 1 < currentSlide) {
                    currentSlide = 0;
                };
                changeSlide(currentSlide);
                activeDot(currentSlide);
            });
        btnPrev.addEventListener('click',
            function() {
                currentSlide--;
                if (0 >= currentSlide) {
                    currentSlide = 0;
                };
                changeSlide(currentSlide);
                activeDot(currentSlide);
            });

        dotsSlide.addEventListener('click',
            function(e) {
                if (e.target.classList.contains('dot')) {
                    const slide = e.target.dataset.slide;
                    changeSlide(slide);
                    activeDot(slide);
                }
            });
    }
    Slider();
} catch (e) {}

async function initMap() {
    // Request needed libraries.
    if(typeof google === 'undefined') {
        return;
    }
    //@ts-ignore
    const [{
        Map
    }] = await Promise.all([
        google.maps.importLibrary("places")
    ]);

    //@ts-ignore
    const placeAutocomplete = new google.maps.places.PlaceAutocompleteElement({
        types: ['pharmacy'],
      });

    //@ts-ignore
    placeAutocomplete.id = "place-autocomplete-input";

    const card = document.getElementById("place-autocomplete-card");

    //@ts-ignore
    card.appendChild(placeAutocomplete);

    // Add the gmp-placeselect listener, and display the results on the map.
    //@ts-ignore
    placeAutocomplete.addEventListener("gmp-placeselect", async ({
        place
    }) => {
        await place.fetchFields({
            fields: ["displayName", "formattedAddress", "location","nationalPhoneNumber"],
        });

        updateInfoWindow(place);

    });
}

// Helper function to create an info window.
function updateInfoWindow(data) {
   document.getElementById('textarea-05708e2f').value = data.displayName + " \n " + data.formattedAddress + " \n " + data.nationalPhoneNumber;
}
try {
    initMap();
} catch(e) {
    console.log("Could not load map.");
}
